<template>
    <div class="operational-main">
        <b-row>
            <b-col sm="9">
                <div class="div-title text-left">
                    <span class="title-main">Recorrência</span>
                    <div class="div-filter">
                        <RadioGroup :options="[
                            { text: 'Mês atual', value: 'month' },
                        ]" v-model="filterRecurring" />
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="3">
                        <div class="panel-child panel-output">
                            <div class="side-by-side">
                                <div class="panel-icon panel-icon-shipping">
                                    <i class="fa-solid fa-retweet icon-fontawesome"></i>
                                </div>
                            </div>
                            <HideShow :iconSize="18">
                                <div style="font-size: 18px" class="title text-center">
                                    {{ data.recorrenciaPrevista | currency }}
                                </div>
                            </HideShow>
                            <div class="legend text-center">Previsão</div>
                        </div>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="3">
                        <div class="panel-child panel-output">
                            <div class="side-by-side">
                                <div class="panel-icon panel-icon-output">
                                    <i class="fa-solid fa-paper-plane-top icon-fontawesome"></i>
                                </div>
                            </div>
                            <HideShow :iconSize="18">
                                <div style="font-size: 18px" class="title text-center">
                                    {{ data.recorrenciaEmitida | currency }}
                                </div>
                            </HideShow>
                            <div class="legend text-center">Emitida</div>
                        </div>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="3">
                        <div class="panel-child panel-output">
                            <div class="side-by-side">
                                <div class="panel-icon panel-icon-hourglass">
                                    <i class="fa-solid fa-hourglass icon-fontawesome"></i>
                                </div>
                            </div>
                            <HideShow :iconSize="18">
                                <div style="font-size: 18px" class="title text-center">
                                    {{ data.recorrenciaPendente | currency }}
                                </div>
                            </HideShow>
                            <div class="legend text-center">Pendente</div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <br />
        <b-row>
            <b-col sm="9">
                <div class="div-title">
                    <span class="title-main">Boleto</span>
                    <div class="div-filter">
                        <RadioGroup :options="[
                            { text: 'Mês atual', value: 'month' },
                            { text: 'Ano atual', value: 'year' },
                        ]" v-model="filter" />
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="2">
                        <div class="panel-child panel-output">
                            <div class="panel-icon panel-icon-money">
                                <i class="fa-solid fa-chart-mixed-up-circle-dollar icon-fontawesome"></i>
                            </div>
                            <HideShow :iconSize="18">
                                <div style="font-size: 18px" class="title text-center">
                                    {{ data.registrado | currency }}
                                </div>
                            </HideShow>
                            <div class="legend text-center">A Vencer</div>
                        </div>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="2">
                        <div class="panel-child panel-output">
                            <div class="panel-icon panel-icon-danger">
                                <i class="fa-sharp fa-solid fa-triangle-exclamation icon-fontawesome"></i>
                            </div>
                            <HideShow :iconSize="18">
                                <div style="font-size: 18px" class="title text-center">
                                    {{ data.emAtraso | currency }}
                                </div>
                            </HideShow>
                            <div class="legend text-center">Em Atraso</div>
                        </div>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="2">
                        <div class="panel-child panel-output">
                            <div class="panel-icon panel-icon-output">
                                <i class="fa-solid fa-dollar-sign icon-fontawesome"></i>
                            </div>
                            <HideShow :iconSize="18">
                                <div style="font-size: 18px" class="title text-center">
                                    {{ data.liquidado | currency }}
                                </div>
                            </HideShow>
                            <div class="legend text-center">Liquidado</div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>


    </div>
</template>

<script>
import HideShow from "@nixweb/nixloc-ui/src/component/layout/HideShow.vue";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";

import { mapActions } from "vuex";

export default {
    name: "PanelBillet",
    components: {
        HideShow,
        RadioGroup
    },
    data() {
        return {
            urlGet: "/api/v1/billing/billet/total-dashboard",
            data: {},
            filter: "month",
            filterRecurring: "month"
        };
    },
    mounted() {
        this.getAll();
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        getAll() {
            this.loading = true;
            let params = { url: this.urlGet, obj: { any: this.filter } };
            this.getApi(params).then((response) => {
                this.data = response.content;
            });
        },
    },
    watch: {
        filter: {
            handler() {
                this.getAll();
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.operational-main {
    margin-left: -10px;
}

.div-title {
    margin-bottom: 15px;
}

.panel-child {
    height: 130px !important;
    width: 94% !important;
    border-radius: 20px;
    margin-left: 18px;
    margin-bottom: 10px;
    padding: 10px;
}

.panel-icon {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.panel-icon-output {
    background-color: #38a169;
}

.panel-output {
    background-color: #f8f9fa;
}

.panel-icon-money {
    background-color: #0008aa;
}

.panel-icon-emitted {
    background-color: #007bff;
}

.panel-icon-hourglass {
    background-color: #a09604;
}

.panel-icon-danger {
    background-color: red;
}

.panel-icon-shipping {
    background-color: rgb(54, 128, 212);
}

.icon-fontawesome {
    color: #fdfdfd;
    font-size: 14px;
}

.title-main {
    font-size: 20px;
    margin-left: 20px;
}

.total {
    font-size: 22px !important;
    font-weight: bold !important;
    margin-top: 1px !important;
}

.legend {
    font-size: 16px;
}

.div-filter {
    margin-left: 25px;
}

.div-recorrencia {
    margin-top: 5px;
}

.div-icon {
    margin-top: -10px !important;
}
</style>