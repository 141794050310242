<template>
  <div class="teste">
    <!-- <Warning :companyId="87" /> -->
    <!-- <Payment :companyId="87" /> -->
    <div class="div-main">
      <!--  <PanelBillet v-if="hasRule('Billing')" /> -->
    </div>
  </div>
</template>

<script>
import PanelBillet from '../../../components/modules/dashboard/billet/PanelBillet.vue'
import Warning from "@nixweb/nixloc-ui/src/component/signature/Warning.vue";
import Payment from "@nixweb/nixloc-ui/src/component/signature/Payment.vue";

import { mapGetters } from "vuex";

export default {
  name: "DashboardView",
  components: {
    Warning,
    Payment, PanelBillet
  },
  computed: {
    ...mapGetters("user", ["hasRule"]),
  },
};
</script>

<style>
.div-main {
  margin-top: 50px;
  margin-left: 120px;
  margin-right: 50px;
}
</style>
